@font-face {
  font-family: gteFoobar;
  src: url(/static/media/foobar.12b2075b.ttf);
}
 #cardTitle{
  font-family: gteFoobar;
  color: rgb(0, 48, 87);
 }

 #mainContainer{
  --bs-gutter-x: 0rem;
 }

 #navRow{
  --bs-gutter-x: 0rem;
 }
 
 #navBar{
  padding-left: 10px;
 }

 /* For mobile phones: */
  #gteLogo{
    width: 100%;
    height: auto;
  }
  #card{
    margin-top: 2%;
    width: 300px;
    align-items: center;
    background-color: white;
    border-color: white;
  }
  
  @media only screen and (min-width: 600px) {
    /* For tablets: */
    #gteLogo{
      width: 60%;
      height: auto;
    }
    #card{
      margin-top: 2%;
      width: 400px;
      align-items: center;
      background-color: white;
      border-color: white;
    }
  }
  
  @media only screen and (min-width: 768px) {
    /* For desktop: */
    #gteLogo{
      width: 80%;
      height: auto;
    }
    #mainImage{
      width: 100%;
    }
    #card{
      margin-top: 2%;
      width: 400px;
      align-items: center;
      background-color: white;
      border-color: white;
    }
  } 
@font-face {
    font-family: gteInter;
    src: url(/static/media/Inter-Regular.b396b059.ttf);
}
body {
    font-family: gteInter;
}

